import React from "react";
import {SocialDataDarkIcons} from "../../data/social";
import "./social.css";

function SocialContact() {
    return (
        <div className="social-contact">
            {SocialDataDarkIcons.map ((item) => {
                return (
                    <a href={item.link}>
                        <div className="social-icon-div">
                            <img src={item.icon} className="social-icon" alt={"Icon"}/>
                        </div>
                    </a>
                );
            })}
        </div>
    );
}

export default SocialContact;