import React from "react";
import {ProjectData} from "../../data/projects";
import ProjectTab from "./projectTab";
import "./projectTab.css";
import "./projects.css"

function Projects() {
    return (
        <div>
            <div className="projects-title">My Projects</div>
            <div className="projects">
                {ProjectData.map((project) => {
                    return <ProjectTab project={project} />;
                })}
            </div>
        </div>
    );
}

export default Projects;