import React from "react";
import "./interests.css";
import {Languages} from "../../data/languages";

function Interests() {
    return (
        <div className="interests">
            <div className="interests-title">My interests</div>
            <div className="interests-body">
                <br />
                I'm interested in Cybersecurity and Cryptography, compilers and programming languages but also in algorithms and data structures.
                <br />
                I'm also passionate about Artificial Intelligence and Machine Learning.
                <br />
                <br />
                I have a BSc in Computer Engineering (Politecnico di Milano - PoliMi).
                <br />
                I'm currently studying for Computer Science and Engineering MSc (with a particular focus on Cybersecurity/Cryptography).
                <br />
                <br />
                My preferred languages are C, Rust, Haskell and Python.<br />
                I've developed both academic and personal project using Javascript/Typescript (and React), HTML/CSS and SQL. < br/>
                I have studied OOP during my BSc with a particular focus on Java.<br />
                I've studied functional programming and languages during my MSc (Scheme/Racket, Haskell, Erlang).<br />
                <br />
                <div className="languages">
                    {Languages.map ((item) => {
                        return (
                            <div>
                                <img src={"/assets/"+item.language.toLowerCase()+".png"} className="icon" alt={"Icon"}/>
                            </div>
                        );
                    })}

                </div>
                <div className="languages_separator"><br /></div>
                I'm also passionate about history and music, especially jazz.<br />
                I currently study jazz guitar at music school.<br />
                I play piano and bass as self-taught.<br />
                <br />
            </div>
        </div>
    );
}

export default Interests;