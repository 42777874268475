import * as React from 'react';
import {DarkModeSwitch} from 'react-toggle-dark-mode';

const ToggleDarkLight = () => {
        const [isDarkMode, setDarkMode] = React.useState(false);

        const toggleDarkMode = (checked: boolean) => {
            setDarkMode(checked)

            // (!) Not a null reference
            const home: HTMLElement = document.getElementById("home")!
            // Manage separators
            const separators = document.getElementsByClassName("separator")
            // Manage all GitHub icons under projects
            const githubLogos = document.getElementsByClassName("project-image-github")
            // Manage social network icons in upper and lower part of the website
            const socialIcons = document.getElementsByClassName("social-icon")

            // Change website colors
            if (!checked) {
                home.style.backgroundColor = "#ffffff"
                home.style.color = "#000000"
            } else {
                home.style.backgroundColor = "#222222F0"
                home.style.color = "#ffffff"
            }

            // Change separator colors
            for (let i = 0; i < separators.length; i++) {
                const currSeparator: HTMLElement = separators[i] as HTMLElement
                if (!checked) {
                    currSeparator.style.backgroundColor = "#000000"
                } else {
                    currSeparator.style.backgroundColor = "#ffffff"
                }
            }

            // Change GitHub logos color
            for (let i = 0; i < githubLogos.length; i++) {
                const currLogo = githubLogos[i] as HTMLImageElement
                if (!checked) {
                    currLogo.src = "/assets/github.png";
                } else {
                    currLogo.src = "/assets/githubWhite.png"
                }
            }

            // Change social icons color
            for (let i = 0; i < socialIcons.length; i++) {
                const currSocialIcon = socialIcons[i] as HTMLImageElement
                let src = currSocialIcon.src.split('assets')
                if (!checked) {
                    switch ("/assets" + src[1]) {
                        case '/assets/githubWhite.png':
                            currSocialIcon.src = "/assets/github.png"
                            break
                        case '/assets/linkedinWhite.png':
                            currSocialIcon.src = "/assets/linkedin.png"
                            break
                    }
                } else {
                    switch ("/assets" + src[1]) {
                        case '/assets/github.png':
                            currSocialIcon.src = "/assets/githubWhite.png"
                            break
                        case '/assets/linkedin.png':
                            currSocialIcon.src = "/assets/linkedinWhite.png"
                            break
                    }
                }
            }
        }

        return (
            <DarkModeSwitch
                checked={isDarkMode}
                onChange={toggleDarkMode}
                size={64}
                style={{marginLeft: "32px"}}
            />
        );
    }

export default ToggleDarkLight