import React from "react";
import "./contact.css";
import SocialContact from "../../shared/social/social";
function Contact() {
    return (
        <div>
            <div className="contact-title">
                <b>Contact me!</b>
            </div>
            <div className="contact-body">
                If you want to get in touch, contact me using the following platforms.
            </div>
            <SocialContact />
        </div>
    );
}

export default Contact;