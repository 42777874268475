export const Languages = [
    {
        language: "C"
    },
    {
        language: "Java"
    },
    {
        language: "Rust"
    },

    {
        language: "Haskell"
    },
    {
        language: "Python"
    },
    {
        language: "Javascript"
    },
    {
        language: "Typescript"
    },
    {
        language: "React"
    },
    {
        language: "Kotlin"
    },
    {
        language: "VHDL"
    },
    {
        language: "Scheme"
    },
    {
        language: "Erlang"
    }
];

