import React from "react";
import "./body.css";
import About from "./about/about"
import Projects from "./projects/projects";
import Separator from "../shared/separator";
import Contact from "./contact/contact"
import Interests from "./interests/interests"

function Body() {
    return (
        <div className="body">
            <section id="About">
                <About />
            </section>
            <Separator />
            <section id="Projects">
                <Projects />
            </section>
            <Separator />
            <section id="Interests">
                <Interests />
            </section>
            <Separator />
            <section id="Contact">
                <Contact />
            </section>
        </div>
    );
}

export default Body;